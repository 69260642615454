
/*!*_secondary: #BA55D3;*!*/

/*!*html {*!*/
/*!*  background: #222222 !important;*!*/
/*!*  min-height: 100%;*!*/
/*!*  color: aliceblue;*!*/
/*!*}*!*/

/*h1 {*/
/*  font-family: "Lucida Console", fantasy;*/
/*  font-size: 25px;*/
/*  font-style: normal;*/
/*  font-variant: normal;*/
/*  font-weight: 400;*/
/*  line-height: 25px;*/
/*}*/

/*h3 {*/
/*  font-family: "Lucida Console", "Lucida Sans Typewriter", monaco, "Bitstream Vera Sans Mono", monospace;*/
/*  font-size: 14px;*/
/*  font-style: normal;*/
/*  font-variant: normal;*/
/*  font-weight: 700;*/
/*  line-height: 15.4px;*/
/*}*/

/*p {*/
/*  font-family: "Lucida Console", "Lucida Sans Typewriter", monaco, "Bitstream Vera Sans Mono", monospace;*/
/*  font-size: 14px;*/
/*  font-style: normal;*/
/*  font-variant: normal;*/
/*  font-weight: 400;*/
/*  line-height: 20px;*/
/*}*/

/*blockquote {*/
/*  font-family: "Lucida Console", "Lucida Sans Typewriter", monaco, "Bitstream Vera Sans Mono", monospace;*/
/*  font-size: 21px;*/
/*  font-style: normal;*/
/*  font-variant: normal;*/
/*  font-weight: 400;*/
/*  line-height: 30px;*/
/*}*/

/*pre {*/
/*  font-family: "Lucida Console", "Lucida Sans Typewriter", monaco, "Bitstream Vera Sans Mono", monospace;*/
/*  font-size: 13px;*/
/*  font-style: normal;*/
/*  font-variant: normal;*/
/*  font-weight: 400;*/
/*  line-height: 18.5714px;*/
/*}*/

/*section {*/
/*  height: 100vh;*/
/*}*/

/*.landing {*/

/*}*/

.circular--portrait {
    position: relative;
    width: 420px;
    height: 420px;
    overflow: hidden;
    border-radius: 50%;
}

.circular--portrait img {
    width: 100%;
    height: auto;
    margin-top: -52px;
}

@media only screen and (max-width: 1050px) {
    /* For mobile phones: */
    .circular--portrait{
        width: 330px;
        height: 330px;
    }
}

@media only screen and (max-width: 830px) {
    /* For mobile phones: */
    .circular--portrait{
        width: 300px;
        height: 300px;
    }
}