
@import "assets/styles/iportfolio.css";
@import "assets/styles/theme.css";
@import "assets/styles/landing.css";

* {
    font-family: Lucida Console, Lucida Sans Typewriter, monaco, Bitstream Vera Sans Mono, monospace;
    margin: 0;
}

html {
    min-height: 100% !important;
}

